import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { charlieTextFontFamily } from '../FontFamily';
export const baseOverrideStyles = _objectSpread(_objectSpread({}, charlieTextFontFamily), {}, {
  fontSize: '1rem',
  borderRadius: '4px',
  padding: "0 ".concat("var(--ds-space-200, 16px)"),
  fontWeight: 600,
  height: '40px',
  lineHeight: '1.25rem',
  alignItems: 'center'
});
export const linkOverrideStyles = _objectSpread(_objectSpread({}, baseOverrideStyles), {}, {
  color: "var(--ds-link)",
  background: 'none',
  border: 'none',
  height: '1.25rem',
  padding: 0,
  '&:hover': {
    textDecoration: 'underline'
  }
});
export const ghostOverrideStyles = _objectSpread(_objectSpread({}, baseOverrideStyles), {}, {
  background: 'transparent',
  color: "var(--ds-text-brand)",
  border: "1px solid ".concat("var(--ds-border-brand)"),
  '&:hover': {
    background: "var(--ds-background-selected-hovered)"
  },
  '&:active': {
    background: "var(--ds-background-selected-pressed)"
  }
});